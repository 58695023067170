
import { Component, Vue } from 'vue-property-decorator';
import { KEY } from '@/inversify.keys';
import { inject } from '@/inversify';
import type UserService from '@/modules/user/user.service';

@Component
export default class TabsGeneral extends Vue {
    @inject(KEY.UserService) private userService!: UserService;

    get tabs() {
        return [
            'scan-properties',
            'intradays',
            'alerts-notifications',
            'email-reports',
            'color-palette',
            'default-filters',
        ];
    }

    get pathName() {
        if (this.userService.isViewAsHotel) {
            return 'hotel.settings.general';
        }

        return 'cluster.settings.general';
    }
}
